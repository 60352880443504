<template>
  <div class="widget-inner">
    <ul v-if="data !== null">
      <li v-for="top_earner in data" :key="`toppoints_${_uid}_${top_earner.user.id}`">
        <user-card :user="top_earner.user" :height="24" />
        <span>{{$formatPoints(top_earner.count)}}</span>
      </li>
    </ul>
    <div v-else class="empty">
      No data for this date range.
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  mounted() {
    this.$emit('loaded')
  }
}
</script>

<style lang="scss" scoped>
.empty {
  text-align: center;
  color: $muted-text;
}
ul {
  display: block;
  margin: 0;
  padding: 0;

  > li {
    display: flex;
    align-items: center;

    + li {
      margin-top: 10px;
    }

    .user--card {
      font-weight: 700;
      font-size: 14px;
    }

    > span {
      display: block;
      font-size: 14px;
      margin: 0 0 0 auto;
      color: $muted-text;
    }
  }
}
</style>